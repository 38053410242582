// Angular
import { Routes } from '@angular/router';
// Auth
import { authGuard, loggedInAuthGuard } from './core/auth';
// Routes
export const appRoutes: Routes = [
    {
        path: 'auth',
        canActivate: [loggedInAuthGuard],
        loadChildren: () => import('./modules/auth/auth.routes').then((m) => m.authRoutes),
    },
    {
        path: 'error',
        loadChildren: () => import('./modules/errors/errors.routes').then((m) => m.errorsRoutes),
    },
    {
        path: 'refresh',
        loadComponent: () => import('./_metronic/layout/components/splash-screen/splash-screen.component').then(m => m.SplashScreenComponent),
    },
    {
        path: 'redirect',
        loadComponent: () => import('./_metronic/layout/components/redirect-screen/redirect-screen.component').then(c => c.RedirectScreenComponent)
    },
    {
        path: '',
        canActivate: [authGuard],
        loadChildren: () => import('./_metronic/layout/layout.routes').then((m) => m.layoutRoutes),
    },
    { path: '**', redirectTo: 'error/404' },
];
